import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/helpers/Validation";
import ApproveDialog from "@/components/shared/Approve.vue";
import UserModule from "@/store/module/user/UserModule";
let Settings = class Settings extends Vue {
    constructor() {
        super(...arguments);
        this.approve = {
            email: false,
            password: false,
        };
        this.disableSendVerification = false;
        this.canInputEmail = true;
        this.verification = {
            timer: {
                show: false,
                interval: null,
                start: null,
                time: "01:00",
            },
            resendButtonDisabled: true,
        };
        this.email = {
            ref: "email",
            type: "input",
            label: "Введите новый e-mail адрес",
            placeholder: "example@mail.ru",
            value: "",
            showOnlyForSuperAuth: false,
            rules: [
                Validation.required,
                Validation.email,
                (value) => value !== this.currentEmail || "Вы ввели текущий адрес",
            ],
            show: true,
        };
        this.emailCode = {
            ref: "emailCode",
            type: "input",
            label: "Введите код, который пришел вам на почту",
            value: "",
            placeholder: "000000",
            showOnlyForSuperAuth: false,
            rules: [Validation.required, Validation.int],
            show: false,
        };
        this.emailFields = [this.email, this.emailCode];
        this.oldPassword = {
            ref: "oldPassword",
            type: "input",
            label: "Введите текущий пароль",
            placeholder: "********",
            value: "",
            showOnlyForSuperAuth: false,
            mode: false,
            rules: [Validation.required, (value) => value.length >= 6 || "Пароль должен состоять минимум из 6 символов"],
        };
        this.newPassword = {
            ref: "newPassword",
            type: "input",
            label: "Введите новый пароль",
            value: "",
            placeholder: "********",
            showOnlyForSuperAuth: false,
            mode: false,
            rules: [Validation.required, (value) => value.length >= 6 || "Пароль должен состоять минимум из 6 символов"],
        };
        this.newPasswordRepeat = {
            ref: "newPasswordRepeat",
            type: "input",
            label: "Введите новый пароль еще раз",
            value: "",
            placeholder: "********",
            showOnlyForSuperAuth: false,
            mode: false,
            rules: [
                Validation.required,
                (value) => value.length >= 6 || "Пароль должен состоять минимум из 6 символов",
                (value) => value === this.newPassword.value || "Пароли должны совпадать",
            ],
        };
        this.passwordFields = [this.oldPassword, this.newPassword, this.newPasswordRepeat];
    }
    get currentEmail() {
        if (!this.user)
            return "";
        return this.user.email;
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get canSendVerification() {
        return (Validation.isFilled(this.email.value) && Validation.isEmail(this.email.value) && !this.disableSendVerification);
    }
    get canUpdateEmail() {
        return Validation.isFilled(this.emailCode.value) && Validation.isInt(this.emailCode.value);
    }
    get canUpdatePassword() {
        return (Validation.isFilled(this.oldPassword.value) &&
            Validation.isFilled(this.newPassword.value) &&
            Validation.isFilled(this.newPasswordRepeat.value));
    }
    async hasEmail() {
        const response = await this.$store.dispatch(UserModule.types.actions.IS_USER_EXISTED_BY, {
            email: this.email.value,
        });
        if (response.status) {
            this.$notify({
                title: "Пользователь с таким email уже зарегестрирован",
                duration: 3000,
                type: "warning",
            });
            return true;
        }
        return false;
    }
    async sendVerificationCode() {
        const hasError = Validation.validateWithView([this.email], this.$refs);
        if (hasError)
            return false;
        if (await this.hasEmail())
            return false;
        await this.$store.dispatch(UserModule.types.actions.SEND_EMAIL_CODE, {
            id: this.user.id,
            email: this.email.value,
        });
        this.verification.resendButtonDisabled = true;
        this.verification.timer.start = new Date();
        this.verification.timer.show = true;
        this.$notify({
            title: `Код подтверждения отправлен на ${this.email.value}`,
            duration: 5000,
            type: "success",
        });
        this.verification.timer.interval = setInterval(() => {
            const secondsBeforeTimerDone = 60 - Math.floor((Date.now() - this.verification.timer.start.getTime()) / 1000);
            if (secondsBeforeTimerDone <= 0) {
                clearInterval(this.verification.timer.interval);
                this.verification.timer.interval = null;
                this.verification.timer.show = false;
                this.verification.resendButtonDisabled = false;
                return;
            }
            const secondsView = secondsBeforeTimerDone < 10 ? `00:0${secondsBeforeTimerDone}` : `00:${secondsBeforeTimerDone}`;
            this.verification.timer.time = secondsView;
        }, 1000);
        this.emailCode.show = true;
    }
    async updateEmail() {
        const hasError = Validation.validateWithView([this.emailCode], this.$refs);
        if (hasError)
            return false;
        await this.$store.dispatch(UserModule.types.actions.VERIFY_EMAIL_CODE, {
            userId: this.user.id,
            email: this.email.value,
            code: parseInt(this.emailCode.value),
            login: false,
        });
        await this.$store.dispatch(UserModule.types.actions.UPDATE_USER, {
            userId: this.user.id,
            user: {
                email: this.email.value,
            },
        });
        this.$notify({
            title: "Ваш email успешно обновлен!",
            duration: 5000,
            type: "success",
        });
        this.disableSendVerification = false;
        this.verification.timer.show = false;
        clearInterval(this.verification.timer.interval);
        this.verification.timer.start = null;
        this.verification.timer.interval = null;
        this.emailCode.show = false;
        this.emailCode.value = "";
        this.email.value = "";
        this.canInputEmail = true;
    }
    async updatePassword() {
        const hasError = Validation.validateWithView(this.passwordFields, this.$refs);
        if (hasError)
            return false;
        const response = await this.$store.dispatch(UserModule.types.actions.CHECKOUT_USER_PASSWORD, {
            id: this.user.id,
            password: this.oldPassword.value,
        });
        console.log(response);
        if (response.status === false) {
            return this.$notify({
                title: "Введен неверный текущий пароль!",
                duration: 3000,
                type: "error",
            });
        }
        await this.$store.dispatch(UserModule.types.actions.UPDATE_USER, {
            id: this.user.id,
            user: {
                password: this.newPasswordRepeat.value,
            },
        });
        this.$notify({
            title: "Ваш пароль успешно обновлен!",
            duration: 5000,
            type: "success",
        });
        this.passwordFields.map((f) => (f.value = ""));
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
    }
};
Settings = __decorate([
    Component({
        components: {
            ApproveDialog,
        },
    })
], Settings);
export default Settings;
